import { createApp } from 'vue'
import { createVuetify } from 'vuetify'
import 'vuetify/styles'

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#1867C0',
    secondary: '#03DAC6',
    error: '#212121',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  }
}
  
export default createVuetify({
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    }
  }
})
