import { createApp } from 'vue';
import App from './App.vue';

// 뷰 라우터 받아오기
import router from './router';

// store 받아오기
import stores from './store/store.js'; // [store]

// Vuetify
// import { createVuetify } from 'vuetify'
// import 'vuetify/styles'
// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'

import vuetify from '@/plugins/vuetify.js';

// axios
import axios from 'axios';
import qs from 'qs';

// highchart
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import Highcharts3D from 'highcharts/highcharts-3d';
import Maps from 'highcharts/modules/map';
import exportingInit from 'highcharts/modules/exporting';

// css
import '@/assets/scss/_index.scss';
import '@/assets/scss/vue-navigation-bar.css';

// common function
import commonFn from './commonfn/fn_common';

// import my component
import GlobalPop from '@/components/dialog/Pop.vue';
import GlobalLoadingBar from '@/components/dialog/LoadingBar.vue';

import VueCookies from 'vue-cookies';

// google 애널리틱스 설정
import VueGtag from 'vue-gtag';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

Maps(Highcharts);

exportingInit(Highcharts);
Highcharts3D(Highcharts);

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params);
};
axios.interceptors.request.use(
  function (config) {
    config.headers.authtoken = stores.state.userStore.token;

    return config;
  }
);
axios.interceptors.response.use(
  function (response) {
    if (response.headers.authtoken !== undefined) stores.commit('userStore/login', response.headers.authtoken);

    return response;
  }
);

const app = createApp(App);
app.use(router); // 라우터 사용
app.use(vuetify); // vuetify
app.use(stores); // [스토어 사용 설정]
app.use(HighchartsVue); // [HighchartsVue]
// app.use(VueGtag, {
//   config: { id: "G-Y4CEF93SWD" }
// })
app.use(VueCookies);
app.$cookies.config('1d');
app.use(VueGtag, {
  config: { id: 'G-23VXVZWNDP' },
});
app.config.globalProperties.$axios = axios;
app.use(commonFn);
app
  .component('GlobalPop', GlobalPop)
  .component('GlobalLoadingBar', GlobalLoadingBar);
app.mount('#app');
